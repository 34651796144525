var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("StarExplosion", {
    ref: "stars",
    attrs: {
      shape: "square",
      radius: _vm.distance,
      nOfStar1: 12,
      nOfStar2: 24,
      maxStar1Size: 28,
      maxStar2Size: 16,
    },
    on: { ready: _vm.init },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }