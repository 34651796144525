













import { defineComponent, ref, toRefs } from "@vue/composition-api"

import StarExplosion from "@/components/GroupTeams/Common/StarExplosion.vue"

export default defineComponent({
  name: "JeopardAiStars",
  components: {
    StarExplosion
  },
  props: {
    size: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { size } = toRefs(props)
    const stars = ref()
    const distance = Math.floor(size.value / 2) + 50
    const init = () => {
      stars.value?.animate()
    }
    return { stars, init, distance }
  }
})
